import { LoggedUser, UserApiSchema, UserFromApi } from '@/lib/types/user';
import { convertKeysToCamelCase } from '@/lib/utils/case-convertor';
import { Store, StoreApiSchema, StoreFromApi } from '@/lib/types/store';
import { buildRegion } from '@utils/region-buillder';
import { Region, RegionApiSchema, RegionFromApi } from '../types/region';
import { useCatalogRequest } from '../hooks/use-catalog-request';
import { buildUser } from '../utils/user-builder';

export type ListApiResponse = {
  count: number;
  offset: number;
  limit: number;
};

export const useGlobalData = () => {
  const { catalogRequest } = useCatalogRequest();

  const getLoggedUser = async () => {
    let result: LoggedUser | null = null;
    try {
      result = await catalogRequest('GET', '/users/me').then((res) => {
        const { body } = res;
        const user: LoggedUser = buildUser(
          UserApiSchema.parse(body.user),
        ) as LoggedUser;
        if (body.impersonate) {
          user.impersonate = buildUser(UserApiSchema.parse(body.impersonate))!;
        }
        return user;
      });
    } catch (error) {
      console.error('Failed to fetch user ', error);
    }
    return result;
  };

  const logout = async () => {
    try {
      await catalogRequest('DELETE', '/auth');
    } catch (error) {
      console.error('Failed to logout', error);
    }
  };

  const getStore = async () => {
    let result: Store | null = null;
    try {
      result = await catalogRequest('GET', '/store').then((res) => {
        const { body } = res;
        const store = StoreApiSchema.parse(body.store);
        return convertKeysToCamelCase<StoreFromApi, Store>(store, 3);
      });
    } catch (error) {
      console.error('Failed to fetch store ', error);
    }
    return result;
  };

  const getRegions = async () => {
    let result: Region[] | null = null;
    try {
      result = await catalogRequest('GET', '/regions').then((res) => {
        const { body } = res;
        const parsedRegions = body.regions.map((region: RegionFromApi) =>
          RegionApiSchema.parse(region),
        );
        return parsedRegions.map((region: RegionFromApi) =>
          buildRegion(region),
        );
      });
    } catch (error) {
      console.error('Failed to fetch regions ', error);
    }
    return result;
  };

  const getAgentById = async (id: string): Promise<UserFromApi> => {
    const path = `/users/${id}`;
    const response = await catalogRequest('GET', path);
    const user = UserApiSchema.parse(response.body.user);
    return user;
  };

  const getCustomerByEmail = async (email: string): Promise<boolean> => {
    const path = `/customers?q=${email}`;
    const response = await catalogRequest('GET', path);
    return response.body.count > 0;
  };

  return {
    getLoggedUser,
    logout,
    getStore,
    getRegions,
    getAgentById,
    getCustomerByEmail,
  };
};
