import { QueryFunction } from '@tanstack/react-query';
import {
  SmartOrderSettings,
  SellerFrontSettings,
  CoreSettings,
} from '@startcatalog/catalog-types-package';

export const useSettingsData = () => {
  const getSellerAdminSettingsFromClient = async (
    sellerId: string,
  ): Promise<{
    SellerFrontSettings: SellerFrontSettings;
    SmartOrderSettings: SmartOrderSettings;
    CoreSettings: CoreSettings;
  }> => {
    const response = await fetch(
      `/api/seller-admin-settings?sellerId=${sellerId}`,
    );
    return response.json();
  };
  const getTestSellerAdminSettingsFromClient = async (
    sellerId: string,
  ): Promise<{
    SellerFrontSettings: SellerFrontSettings;
    SmartOrderSettings: SmartOrderSettings;
    CoreSettings: CoreSettings;
  }> => {
    const response = await fetch(
      `/api/test-admin-settings?sellerId=${sellerId}`,
    );
    return response.json();
  };

  const getSellerAdminSettingsFromReactQuery: QueryFunction<
    {
      SellerFrontSettings: SellerFrontSettings;
      SmartOrderSettings: SmartOrderSettings;
      CoreSettings: CoreSettings;
    },
    ['seller_admin_settings', string]
  > = async ({ queryKey }) => {
    const sellerId = queryKey[1];
    return sellerId === 'catalogtest'
      ? getTestSellerAdminSettingsFromClient(sellerId)
      : getSellerAdminSettingsFromClient(sellerId);
  };

  return {
    getSellerAdminSettingsFromReactQuery,
  };
};
