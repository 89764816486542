import { useQuery } from '@tanstack/react-query';
import { useSettingsData } from '../data/settings/data';
import { useTenant } from '../context/tenant-context';

export const useSellerAdminSettings = () => {
  const { tenantId } = useTenant();
  const { getSellerAdminSettingsFromReactQuery } = useSettingsData();
  const { data: settings } = useQuery({
    queryKey: ['seller_admin_settings', tenantId],
    queryFn: getSellerAdminSettingsFromReactQuery,
  });

  return { ...settings } as const;
};
