import { z } from 'zod';

export const UserApiSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
  email: z.string(),
  first_name: z.nullable(z.string()).optional(),
  last_name: z.nullable(z.string()).optional(),
  role: z.nullable(z.string()).optional(),
  phone: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.object({}).optional()).optional(),
});

export type UserFromApi = z.infer<typeof UserApiSchema>;

export type User = {
  id: string;
  label: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  metadata?: {};
};

export type LoggedUser = User & {
  impersonate?: User;
};

export type Customer = LoggedUser & {
  companyId?: string;
  phone?: string;
  hasAccount?: boolean;
};

export type Agent = User;
export type Contact = User & { phone?: string };

export const PostUserSchema = z.object({
  email: z.string().email(),
  first_name: z.nullable(z.string()).optional(),
  last_name: z.nullable(z.string()).optional(),
  password: z.nullable(z.string()).optional(),
  role: z.nullable(z.string()).optional(),
});

export const UpdateUserSchema = z.object({
  first_name: z.nullable(z.string()).optional(),
  last_name: z.nullable(z.string()).optional(),
  role: z.nullable(z.string()).optional(),
});

export type PostUserFromApi = z.infer<typeof PostUserSchema>;

export type UpdateUserFromApi = z.infer<typeof UpdateUserSchema>;
